import { template as template_b103e5b1a59441e195da98be58af2649 } from "@ember/template-compiler";
export default template_b103e5b1a59441e195da98be58af2649(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
