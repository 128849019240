import { template as template_aaa52db90a5c4617b861f8c21103fd13 } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { htmlUnsafe } from '../../../helpers/html-unsafe';
export default class ModulixExpand extends Component {
    @action
    onExpandToggle(event) {
        const isOpen = event.srcElement.open;
        this.args.onExpandToggle({
            elementId: this.args.expand.id,
            isOpen
        });
    }
    static{
        template_aaa52db90a5c4617b861f8c21103fd13(`
    <details class="modulix-expand" {{on "toggle" this.onExpandToggle}}>
      <summary class="modulix-expand__title">{{@expand.title}}</summary>
      {{htmlUnsafe @expand.content}}
    </details>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
