import { template as template_e3ccd93f15af4771904734c7e31f0475 } from "@ember/template-compiler";
import Footer from '../footer';
import NavbarHeader from '../navbar-header';
export default template_e3ccd93f15af4771904734c7e31f0475(`
  <NavbarHeader />
  {{yield}}
  <Footer />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
