import { template as template_e7cb0a3505344f708c760a2ecdc41b1a } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import CompanionBlocker from '../companion/blocker';
export default class Assessments extends Component {
    @service
    store;
    @action
    async createLiveAlert() {
        const adapter = this.store.adapterFor('assessment');
        await adapter.createCompanionLiveAlert({
            assessmentId: this.args.assessment.id
        });
        this.args.assessment.reload();
    }
    static{
        template_e7cb0a3505344f708c760a2ecdc41b1a(`
    {{#if @assessment.isCertification}}
      <CompanionBlocker @onBlock={{this.createLiveAlert}}>
        {{yield}}
      </CompanionBlocker>
    {{else}}
      {{yield}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
