import { template as template_06062e9f80764c37a473fbdc544d9580 } from "@ember/template-compiler";
import Component from '@glimmer/component';
export default class ModulixFeedback extends Component {
    get type() {
        return this.args.answerIsValid ? 'success' : 'error';
    }
    static{
        template_06062e9f80764c37a473fbdc544d9580(`
    <div class="feedback feedback--{{this.type}}">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
